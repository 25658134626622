<template>
  <div class="organ_page">
    <div class="imgs">
      <img src="../../assets/img/home/organ/teach_1.png" alt="" />
      <img src="../../assets/img/home/organ/teach_2.png" alt="" />
      <img src="../../assets/img/home/organ/teach_3.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'organBlock',
  components: {},
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.organ_page {
  width: 100%;
  .imgs {
    margin-top: 100px;
    @include flex(column, flex-start, center);
    img {
      width: 1000px;
      margin-bottom: 50px;
    }
  }
}
</style>
